<template>
  <div style="padding: 15px; word-break: break-all;">
    <div v-for="(video,idx) in DataList" style="margin-bottom: 20px;" @click="goPlay(video)">
		<div class="videobox flexcenter">
			<img :src="video.image" style="width: 100%;" />
			<div class="player">
				
			</div>
		</div>
		<div class="videoname">{{video.title}}</div>
		<div class="videoprice">
			<span v-if="video.jifen>0">{{video.jifen}}积分兑换</span>
			<span v-if="video.jifen>0">/</span>
			<span v-if="video.xf_money>0">消费满￥{{video.xf_money}}免费</span>
			<span v-if="video.xf_money>0">/</span>
			<span v-if="video.one_money>0">视频价格￥{{video.one_money}}</span>
			
		</div>
	</div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
      
		page:{
			current_page:1,
			total:0
		},
		DataList:[]
      }
    },
    created() {
     

    },
    mounted() {
     this.getList()
    },
    methods: {
     getList(){
		 this.$store.dispatch("GET", {
		 	url: "/api/video/?action=videolist&status=1&page="+this.page.current_page
		 }).then(result => {
		 	this.DataList = result.data.rows;
			this.page.total = result.data.total;
		 	
		 })
	 },
	 goPlay(e){
		 this.$router.push("/videoplay/"+e.id)
	 }
    }
  }
</script>

<style scoped="scoped">
  .videobox{ width: 100%; height: 50vw; overflow: hidden; position: relative;}
  .videoname{ padding: 10px 0 ; font-size: 14px;}
  .videoprice{ font-size: 12px; color: orangered;}
  .player{
	  background-image: url(https://gmbaby.oss-cn-shanghai.aliyuncs.com/gmbaby/2020-1/1580190330984310.png?s=256_256);
	  width: 80px;
	  height: 80px;
	  position: absolute;
	  left: 50%;
	  top: 50%;
	  background-size: cover;
	  transform: translate3d(-40px,-40px,0);
	  
  }
</style>
